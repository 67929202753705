<template>
  <div class="catsec-home">
    <span
      v-for="sport in sportsList"
      :key="sport.id"
      :class="['cattit-home', { active: selectedSport === sport.id }]"
      @click="setSportMenu(sport.id, sport.name)"
    >
      <img :src="sport.icon" class="menu-icon-sports-svg" />
      <span> {{ sport.displayName }} </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'sports',
  data() {
    return {
      selectedSport: null,
      sportsList: [
        {
          id: 1,
          name: 'Soccer',
          displayName: 'Football',
          icon: '/menu/soccer.png',
        },
        {
          id: 2,
          name: 'Basketball',
          displayName: 'Basketball',
          icon: '/menu/basket-ball.png',
        },
        {
          id: 21,
          name: 'Cricket',
          displayName: 'Cricket',
          icon: '/menu/cricket.png',
        },
        {
          id: 5,
          name: 'Tennis',
          displayName: 'Tennis',
          icon: '/menu/tennis.png',
        },
        {
          id: 4,
          name: 'IceHockey',
          displayName: 'Ice Hockey',
          icon: '/menu/icehockey.png',
        },
        {
          id: 16,
          name: 'AmericanFootball',
          displayName: 'American Football',
          icon: '/menu/americanfootball.png',
        },
        {
          id: 10,
          name: 'Boxing',
          displayName: 'Boxing',
          icon: '/menu/boxing.png',
        },
        {
          id: 31,
          name: 'Badminton',
          displayName: 'Badminton',
          icon: '/menu/badminton.png',
        },
        {
          id: 12,
          name: 'Rugby',
          displayName: 'Rugby',
          icon: '/menu/rugby.png',
        },
        {
          id: 23,
          name: 'Volleyball',
          displayName: 'Volleyball',
          icon: '/menu/volleyball.png',
        },
        {
          id: 6,
          name: 'Handball',
          displayName: 'Handball',
          icon: '/menu/handball.png',
        },
        {
          id: 117,
          name: 'MixedMartialArts',
          displayName: 'Mixed Martial Arts',
          icon: '/menu/mma.png',
        },
        {
          id: 22,
          name: 'Darts',
          displayName: 'Darts',
          icon: '/menu/darts.png',
        },
      ],
    };
  },
  methods: {
    getKey: function (index) {
      return Math.random()
        .toString(11)
        .replace('0.', 'sport-' + index + '-');
    },
    setSport: function (sportID) {
      document.getElementById('sports-btn').click();
      this.$store.dispatch('setSportID', sportID);

      if (this.$store.state.current_page === 'home') return;

      this.$router.push({ name: 'home' });
    },

    setSportMenu: function (sport_id, sport_name) {
      // this.dismiss();
      //console.log('setSport ' + JSON.stringify(sport));

      // this.sport = sport;
      this.sport_name = sport_name;
      this.selectedSport = sport_name;
      // this.getMarket(sport.sport_id);
      this.$store.dispatch('setSportID', sport_id);
      this.$store.dispatch('setCurrentPage', sport_name);
      console.log(this.$store.state.sport_id);

      //if(this.$route.path !== "/"){

      this.$router.push({ name: 'sport', params: {} });
      //}
    },
    getMenuTextClass: function (sportID) {
      return parseInt(this.$store.state.sport_id) === parseInt(sportID)
        ? 'ml-2 item-wrapper-span-active'
        : 'ml-2 item-wrapper-span';
    },
    isActiveMenu: function (name) {
      return this.current_page === name ? 'menu-active' : '';
    },
    resetGames: function () {
      this.$store.dispatch('resetAllGames');

      if (this.current_page !== 'home') {
        this.goHome();
      }
    },
  },
  computed: {
    iconSize: function () {
      return 20;
    },
    current_page: function () {
      return this.$store.state.current_page;
    },
    show_freebet: function () {
      var p = this.getProfile();
      if (!p) {
        return true;
      }

      return parseInt(p.b4) > 0;
    },
    sports: function () {
      var s = this.$store.state.sports;
      var t = [];

      this.jQuery.each(s, function (k, v) {
        if (v.sport_id > 1) {
          t.push(v);
        }
      });

      return t;
    },
  },
};
</script>
